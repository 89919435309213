<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='createValidity'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Validity</h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="addValidity" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Validity Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="title">Title</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.title.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Title"
                                    id="title"
                                    @blur="$v.formData.title.$touch()"
                                    v-model.trim="formData.title"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.title.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.required"
                            >Title field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.minLength"
                            >At least 2 character title.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="months">Month</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.months.$error }">
                            <select
                                    class="form-control border-radius-0"
                                    id="months"
                                    @blur="$v.formData.months.$touch()"
                                    v-model.trim.number="formData.months"
                                    required
                            >
                              <option v-for="(m,i) in 12" :key="i" :value="m">{{m}}</option>
                            </select>
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.months.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.months.required"
                            >Branch field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.months.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Discount</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.discount.$error }">
                            <input
                                    type="number"
                                    step="any"
                                    class="form-control border-radius-0"
                                    placeholder="Discount"
                                    @blur="$v.formData.discount.$touch()"
                                    v-model.trim.number="formData.discount"
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.discount.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.discount.required"
                            >User field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.discount.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Type</label>
                          </div>
                          <div class="col-md-6 d-flex">
                            <input
                                    type="radio"
                                    class="form-control border-radius-0"
                                    id="rupees"
                                    v-model.trim.number="formData.type"
                                    value="1"
                            />
                            <label for="rupees" class="mt-2 ml-2">Rupees</label>
                            <input
                                    type="radio"
                                    class="form-control border-radius-0 ml-5"
                                    id="percentage"
                                    v-model.trim.number="formData.type"
                                    value="2"
                            />
                            <label for="percentage" class="mt-2 ml-2">Percentage</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  /* eslint-disable */
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    computed: {
      ...mapGetters([
        "apiRoot",
        "token",
        "processing",
        "fileLocation",
        "eventMessage",
        "dataLists",
        "modalId",
        "modalState",
      ]),
    },
    data() {
      return {
        formData: {
          title: "",
          months: 12,
          discount: "",
          type: 1,
        },
      };
    },
    validations: {
      formData: {
        title: { required, minLength: minLength(2), maxLength: maxLength(255) },
        months: { required, numeric },
        discount: { required, numeric },
        type: { required, numeric },
      },
    },
    methods: {
      addValidity() {
        if (!this.$v.formData.$invalid) {
          this.$store.commit("setApiUrl", "api/validity/store");
          this.$store.commit("addData", this.formData);
        } else {
          this.$store.commit("setEventMessage", `Please fill required fields.`);
        }
      },
    },
    watch: {
      eventMessage(value) {
        if (value && value.indexOf("success") >= 0) {
          this.formData.title = "";
          this.formData.discount = "";
          this.formData.type = 1;
        }
      },
    },
  };
</script>